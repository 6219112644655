<template>
    <div class="d-flex flex-column">
        <v-combobox :readonly="preview" :label="`Section`"  v-model="element.item" maxlength="100"
            color="accent" :items="sections"></v-combobox>
        <v-select :readonly="preview" label="Style" color="accent" v-model="element.style" 
            :items="['Flow text', 'Bullet points']" />
        <v-textarea :readonly="preview" class="exclude-this-item" rows="4" v-model="element.dlevel" 
            label="Your Instructions" auto-grow
            placeholder="Give mdhub instructions on how to write this note e.g. extract direct quotes, comment on mood and anxiety levels..."
            persistent-placeholder density="compact" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    element: Object,
    preview:{
        type: Boolean,
        default: false
    }
})

const sections = [
    'Chief Complaint',
    //HPI
    'History of Present Illness',
    'History of Present Illness - detailed',
    'Subjective',
    // historty section
    'Past Psychiatric History',
    'Past Psychiatric History - Detailed',
    'Review of Psychiatric History',
    'Educational History',
    'Professional History',
    'Social and Family History',
    'Family History',
    'Social History',
    'Interim History',
    'Trauma History',
    'Past Medical and Surgical History',
    'Spiritual and Cultural Factors',
    //Review of Systems
    'Review of Systems',
    'General Review of Function',
    'Mental Status Exam',
    'Objective',
    'Vital Signs',
    'Symptoms',
    //Now
    'Substance Use',
    'Current Medication',
    //Assessment
    'Assessment',
    'Assessment (BPS)',
    'DAYC-2',
    'Assessment and Plan',
    'Risk Assessment',
    'Problem List',
    'Problem list (excl. ICD 10)',
    'Problems Discussed',
    'DSM-5',
    //Plan
    'Plan',
    'Plan (excl. ICD 10)',
    'Protective Factors',
    'Psychotherapeutic Interventions',
    // Progress and next steps
    'Treatment Progress',
    'Patient Goals',
]
</script>