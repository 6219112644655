import { MicrophoneAudioRecorder } from "@/classes/recorder.mic";
import { TabAudioRecorder } from "@/classes/recorder.tab";
import { defineStore } from "pinia";
import { ref, nextTick, watch } from "vue";
import { trackEvent, RECORDER_START_WITHOUT_HEADPHONES, RECORDER_START_WITHOUT_HEADPHONES_ERROR, RECORDER_START_WITH_HEADPHONES, RECORDER_START_WITH_HEADPHONES_ERROR, RECORDER_PAUSE, RECORDER_RESUME, RECORDER_STOP_RECORDING, RECORDER_LIVE_TRANSCRIPT_ZERO_BLOB, RECORDER_TOGGLE_DRAWER } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import useLiveTranscript from '@/composables/useLiveTranscript';

export const useRecorderStore = defineStore('recoder', () => {
    const drawer = ref(false);
    const patientId = ref('');
    const patient = ref(null);
    const recording = ref(false);
    const paused = ref(false);
    const stream = ref(null);
    const startTime = ref(null);
    const sessionId = ref('');
    const overlay = ref(false);
    const elapsedTime = ref(0);
    const downloadAudioDialog = ref(false);
    let timerInterval = null;

    const { user } = getUser()
    const { initializeLiveTranscription, handleLiveStream, finishLiveTranscript } = useLiveTranscript();

    let recorder

    function toggleDrawer(item) {
        if (item) {
            patientId.value = item.id;
            patient.value = item;
        }
        drawer.value = !drawer.value;
    }

    async function startRecordingMic() {
        resetTimer();
        startTimer();
        recording.value = true;

        recorder = new MicrophoneAudioRecorder(user.value.uid, patientId.value, sessionId.value);
        try {
            stream.value = await recorder.start(handleDataAvailable)
            trackEvent(RECORDER_START_WITHOUT_HEADPHONES, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
        } catch (err) {
            trackEvent(RECORDER_START_WITHOUT_HEADPHONES_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { errorName: err.name, errorMessage: err.message, errorStack: err.stack } })
            if (err.name === 'NotAllowedError') {
                return errorAlert("Unable to capture your microphone. Please give permission to the application to use the microphone.")
            }

            return errorAlert("An error occurred while trying to record.")
        }
        startLiveTranscript()
        startTime.value = Date.now()
    }

    async function startRecordingTab() {
        resetTimer();
        startTimer();
        recording.value = true;

        recorder = new TabAudioRecorder(user.value.uid, patientId.value, sessionId.value);
        try {
            stream.value = await recorder.start(handleDataAvailable)
            trackEvent(RECORDER_START_WITH_HEADPHONES, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })

        } catch (err) {
            trackEvent(RECORDER_START_WITH_HEADPHONES_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { errorName: err.name, errorMessage: err.message, errorStack: err.stack } })
            if (err.name === 'NotAllowedError') {
                return errorAlert("The session is not recording. Share a tab to proceed.")
            }

            if (err.name === 'InvalidStateError') {
                return errorAlert("The session is not recording. Share a tab with audio to proceed.")
            }

            // custom error if no audio tracks are found - for Safari and Firefox
            if (err.message === 'NoAudioTrackError') {
                return errorAlert("Audio capture is not supported in this browser for the selected content. For the best experience, please try using Chrome or Edge. If you are already using one of these broswers, please select a tab to share and not a window or a screen.")
            }

            // for Firefox
            if (err.message === 'The object can not be found here.') {
                return errorAlert("Error recording window or screen. Please check your system settings.")
            }

            return errorAlert("An error occurred while trying to record the tab.")

        }
        startLiveTranscript()
        startTime.value = Date.now()
    }

    function pauseRecording() {
        recorder.pause()
        paused.value = true;
        trackEvent(RECORDER_PAUSE, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
    }

    function resumeRecording() {
        recorder.resume()
        paused.value = false;
        trackEvent(RECORDER_RESUME, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
    }

    async function stopRecording() {

        paused.value = false;
        recording.value = false;
        stopTimer();

        recorder.stop()
        trackEvent(RECORDER_STOP_RECORDING, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
        recorder.createAudioBlob()

        finishLiveTranscript()

    }

    function clear() {
        recorder.clear()
        resetTimer();

        recording.value = false;
        paused.value = false;
        stream.value = null;
        startTime.value = null;
        sessionId.value = '';
        patient.value = null;
        patientId.value = '';
    }

    function errorAlert(error) {
        recording.value = false;
        paused.value = false;
        stream.value = null;
        startTime.value = null

        nextTick(alert(error))
    }

    function getBlob() {
        return recorder.getAudioBlob()
    }

    function setSessionId(id) {
        sessionId.value = id;
    }

    function setOverlay(value) {
        overlay.value = value;
    }

    // Live Transcript
    async function startLiveTranscript() {
        await initializeLiveTranscription({
            userId: user.value.uid,
            sessionId: sessionId.value,
            patientId: patientId.value
        })
    }

    function handleDataAvailable(blob) {
        if (blob.size === 0) {
            trackEvent(RECORDER_LIVE_TRANSCRIPT_ZERO_BLOB, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
        }
        handleLiveStream(blob)
    }

    function startTimer() {
        timerInterval = setInterval(() => {
            if (!paused.value) {
                elapsedTime.value++;
            }
        }, 1000);
    }

    function stopTimer() {
        clearInterval(timerInterval);
        timerInterval = null;
    }

    function resetTimer() {
        elapsedTime.value = 0;
    }

    function setDownloadAudioDialog(value) {
        downloadAudioDialog.value = value;
    }

    watch(drawer, (val) => {
        trackEvent(RECORDER_TOGGLE_DRAWER, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, drawer: val })
    })

    return {
        drawer,
        patientId,
        patient,
        recording,
        paused,
        stream,
        startTime,
        sessionId,
        overlay,
        elapsedTime,
        downloadAudioDialog,
        startRecordingMic,
        startRecordingTab,
        pauseRecording,
        resumeRecording,
        stopRecording,
        getBlob,
        clear,
        toggleDrawer,
        setSessionId,
        setOverlay,
        setDownloadAudioDialog,
    }
})