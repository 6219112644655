<template>
  <v-app>
    <Navbar v-if="!$route.meta.hideNavbar" />
    <NavDrawer v-if="!$route.meta.hideNavbar" />
    <v-main :class="['main-background', 'pb-12', { 'mt-6': !$route.meta.hideNavbar }]">
      <!-- Main content -->
      <router-view />

      <RecordingDrawer />
      
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import NavDrawer from './components/NavDrawer.vue';
import getNewVersion from './composables/getNewVersion';
import { startAvailableFeaturesListener } from './composables/getAvailableFeatures'
import { getWhitelistedFeaturesForUser } from './composables/isWhitelistedAccount';
import RecordingDrawer from './components/recorder/Drawer.vue'

export default {
  components: { Navbar, NavDrawer, RecordingDrawer },
  async mounted() {
    const asyncOperations = [getNewVersion(), startAvailableFeaturesListener(), getWhitelistedFeaturesForUser()];
    await Promise.all(asyncOperations);
  },
  data: () => ({
    drawer: true, // or false if you want it to start hidden
  }),
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-background {
  background-color: #F9FBFD;
}
</style>
