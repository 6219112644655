<template>
    <v-dialog v-model="dialogVisible" scrollable class="fixed-height-dialog" persistent>
        <v-card>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <!-- Image container -->
                        <v-col cols="12" md="6" class="pa-0 d-none d-md-block">
                            <v-img class="image-onboarding" :src="require('@/assets/mdhub-banner2.jpg')"
                                alt="Banner Image" cover></v-img>
                        </v-col>

                        <!-- Text container -->
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <div class="d-flex flex-column justify-center align-items-center flex-grow-1">
                                <h1>{{ steps[currentStep].title }}</h1>
                                <p class="ml-8">{{ steps[currentStep].content }}</p>

                                <v-list v-if="currentStep !== steps.length - 1" dense>
                                    <v-list-item v-for="(instruction, index) in steps[currentStep].instructions"
                                        :key="index">
                                        <div>
                                            <div v-if="containsURL(instruction)">
                                                <span v-html="getInstructionWithLink(instruction)"></span>
                                            </div>
                                            <div v-else>
                                                {{ instruction }}
                                            </div>
                                        </div>
                                    </v-list-item>
                                </v-list>

                                <div class="full-width-select" v-if="currentStep == steps.length - 2">
                                    <h4 class="text-left ml-8">How did you find us?</h4>
                                    <v-select class="mr-8 ml-8 mt-8" v-model="selectedOption" label="Select"
                                        :items="['Social Media (e.g., Facebook, Twitter, LinkedIn)', 'Search Engine (e.g., Google, Bing)', 'Recommendation by a colleague or friend', 'Professional Conference or Seminar', 'Community', 'Other (Please specify)']"></v-select>
                                    <v-text-field v-if="selectedOption === 'Other (Please specify)'"
                                        label="Please specify" v-model="otherDetail" class="mr-8 ml-8 mt-2">
                                    </v-text-field>
                                    <h4 class="text-left ml-8 mt-4">What speciality do you practise?</h4>
                                    <v-autocomplete class="mx-8 mt-8" label="Select or type to search"
                                        v-model="selectedSpeciality" clearable item-title="speciality" return-object
                                        :items="specialities"></v-autocomplete>
                                    <v-text-field v-if="selectedSpeciality?.speciality === 'Other'" label="Please specify"
                                        v-model="otherSpeciality" class="mr-8 ml-8 mt-2"></v-text-field>

                                </div>
                            </div>

                            <div class="button-container mr-5 mb-5">
                                <v-row justify="end">
                                    <!-- <v-col cols="auto">
                                        <v-btn variant="plain" text @click="closeOnboarding">
                                            Skip
                                        </v-btn>
                                    </v-col> -->
                                    <v-col cols="auto">
                                        <v-btn variant="text" v-if="currentStep > 0" @click="previousStep">
                                            {{ previouseButtonText }}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="main" cols="auto">
                                        <v-btn variant="tonal" color="#4b53b9" :loading="isPending" @click="nextStep">
                                            {{ nextButtonText }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script setup>


import { ref, watch, defineProps, getCurrentInstance } from 'vue'
import getUser from '@/composables/getUser'
import { trackEvent, NEW_PATIENT_CREATED, USER_SOURCE } from '@/utilities/analyticsService';
import { useRouter } from 'vue-router';
import { getFunctions, httpsCallable } from "firebase/functions";

const props = defineProps(
    {
        isModalOpen: {
            type: Boolean,
            default: false
        }
    }
)

const { emit } = getCurrentInstance();

const { user } = getUser()
const isPending = ref(false)
const dialogVisible = ref(false);
const otherDetail = ref('');
const router = useRouter();
const selectedOption = ref(null)
const steps = ref([
    {
        title: 'Welcome to mdhub!',
        content: "Excited to see you here! Let's get you started.",
        instructions: [],
    },
    {
        title: 'How it works',
        content: '',
        instructions: [
            "Step 1: Audio record either with mobile apps (iOS, Mac, Android) or in-browser recorder.",
            "Step 2: Create a patient profile on the app or web platform.",
            "Step 3: Receive the clinical note within minutes after the patient visit, accessible both on the app and web interface.",
            "Step 4: Review / edit the note with your computer.",
        ],
    },
    {
        title: "Tell us about you!",
        content: 'Your feedback helps us understand how our users discover mdhub. This information is crucial for improving our outreach and helping more professionals like you.',
        instructions: [],
    },
    {
        title: "Let's get started",
        content: 'To audio record with mobile, download the mdhub app. Alternatively, use our seamless in-browser recording functionality.',
        instructions: [
            "Your patient, session, or audio data is thoroughly removed from our servers upon your decision to delete it.",
            "For further insight into our data privacy practices, kindly visit: https://mdhub.ai/privacy-policy.",
            "If you have any inquiries, please contact us at support@mdhub.ai.",
        ],
    },
]
)

const currentStep = ref(0);
const nextButtonText = ref('Next');
const previouseButtonText = ref('Back');
const deviceType = ref('Mobile');


const selectedSpeciality = ref(null)
const otherSpeciality = ref(null)

const functions = getFunctions();

const closeModal = () => {
    emit('close-modal');
}

const handleCreatePatient = async (patientName, gender, diagnosis) => {

    console.log('on handle create patient')

    isPending.value = true

    console.log('SELECTED SPECIALITY', selectedSpeciality.value, otherSpeciality.value)

    const templateId = selectedSpeciality.value?.templateId;

    if (templateId) {
        try {

            const setLastTemplateId = httpsCallable(functions, 'setLastTemplateId');
            await setLastTemplateId({ templateId: templateId })

        } catch (error) {
            console.log('error in saving settings', error)
        }
    }

    const createPatient = httpsCallable(functions, 'createPatient');

    const createTestPatient = {
        patientName: patientName,
        patientDescription: "",
        address: "",
        diagnosis: diagnosis,
        userId: user.value.uid,
        sessions: [],
        gender: gender,
        pronoun: 'she/her',
        dob: '1976-01-01',
    };

    try {

        const { data } = await createPatient(createTestPatient);
        trackEvent(NEW_PATIENT_CREATED, { userId: user.value.uid, userEmail: user.value.email })
        trackEvent(USER_SOURCE, { userId: user.value.uid, userEmail: user.value.email, source: props.selectedOption + ' - ' + props.otherDetail })
        router.push({ name: 'PatientOverview', params: { id: data.id } });
    } catch (error) {
        console.error('error in creating patient', error)

    } finally {
        isPending.value = false
        closeModal();
    }
}

const updateUserSpeciality = async () => {
    const speciality = otherSpeciality.value ? otherSpeciality.value : selectedSpeciality.value?.speciality;
    console.log('speciality', speciality, selectedSpeciality.value, otherSpeciality.value);
    // here we need to update the user speciality 
    const updateUser = httpsCallable(functions, 'updateUser');
    try {
        await updateUser({ speciality: speciality });
    } catch (error) {
        console.log('error in updating user', error);
    }
}

const containsURL = (instruction) => {
    return instruction.includes("http://") || instruction.includes("https://");
}

const extractURL = (instruction) => {
    const regex = /(http[s]?:\/\/[^\s]+)/g;
    const urlMatches = instruction.match(regex);
    return urlMatches ? urlMatches[0] : '';
}

const getInstructionWithLink = (instruction) => {
    const url = extractURL(instruction);
    const linkText = instruction.replace(url, '').trim();
    return `<span>${linkText}</span><a href="${url}" target="_blank">${url}</a>`;
}

const nextStep = async () => {
    let lss = 0
    if (deviceType.value === 'Mobile' || deviceType.value === 'Tablet') {
        lss = steps.value.length - 1
    } else {
        lss = steps.value.length - 2
    }

    if (currentStep.value === lss) {
        currentStep.value = 0;
        nextButtonText.value = 'Next';
        const randomPatient = getRandomPatient();
        await handleCreatePatient(randomPatient.name, randomPatient.gender, randomPatient.diagnosis);
        await updateUserSpeciality();
    } else {
        currentStep.value++;
        if (currentStep.value === lss) {
            nextButtonText.value = 'Create test patient';
        }
    }
}

const previousStep = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
        nextButtonText.value = 'Next';
    }
}

const getRandomPatient = () => {
    const patients = [
        { name: "John Smith", gender: "Male", diagnosis: "Depression" },
        { name: "Jane Doe", gender: "Female", diagnosis: "Anxiety Disorder" },
        { name: "Emily Johnson", gender: "Female", diagnosis: "Bipolar Disorder" },
        { name: "Michael Brown", gender: "Male", diagnosis: "Schizophrenia" },
        { name: "Sarah Davis", gender: "Female", diagnosis: "Obsessive-Compulsive Disorder" },
        { name: "David Wilson", gender: "Male", diagnosis: "Post-Traumatic Stress Disorder" },
        { name: "Lisa Miller", gender: "Female", diagnosis: "Panic Disorder" },
        { name: "James Taylor", gender: "Male", diagnosis: "Eating Disorder" },
        { name: "Jessica White", gender: "Female", diagnosis: "Depression" },
        { name: "Chris Jones", gender: "Male", diagnosis: "Anxiety Disorder" }
    ];

    const randomIndex = Math.floor(Math.random() * patients.length);
    return patients[randomIndex];
};

watch(() => selectedOption, (newVal) => {
    if (newVal !== 'Other (Please specify)') {
        otherDetail.value = ''
    }
})

watch(() => props.isModalOpen, (newVal) => {
    dialogVisible.value = newVal
})

watch(() => dialogVisible.value, (newVal) => {
    if (!newVal) {
        emit('close-modal');
    }
})

const specialities = [
  { speciality: 'Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Child and Adolescent Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Geriatric Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Forensic Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Addiction Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Consultation-Liaison Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Neuropsychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Psychosomatic Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Emergency Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
  { speciality: 'Sleep Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Clinical Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Counseling Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'School Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Industrial-Organizational Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Health Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Neuropsychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Forensic Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Sports Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Developmental Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Social Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Experimental Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Community Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Anesthesiology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Cardiology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Dermatology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Emergency Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Endocrinology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Family Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Gastroenterology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'General Surgery', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Geriatrics', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Hematology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Infectious Disease', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Internal Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Nephrology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Neurology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Nutritionist', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Obstetrics and Gynecology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Oncology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Ophthalmology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Orthopedics', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Otolaryngology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Pediatrics', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Physical Medicine and Rehabilitation', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Plastic Surgery', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Pulmonology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Radiology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Rheumatology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Urology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
  { speciality: 'Other', templateId: 'hed9UvwPtcqnkSFy0qVZ' }
];

</script>
<style scoped>
.image-onboarding {
    max-height: 75vh;
    max-width: 100%;
}

.full-width-select {
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: right;
    margin-top: 20px;
}

.button-container button {
    margin: 0 5px;
}

::v-deep .main .v-btn__content {
    color: #fff !important;
}

::v-deep .main .v-btn__content:hover {
    color: #fff !important;
}

.main .v-btn {
    background-color: #FF7260 !important;
}

@media (max-width: 959px) {
    .image-onboarding {
        display: none;
    }

    .fixed-height-dialog .v-card {
        height: calc(100vh - 64px) !important;
        /* Adjust as necessary */
        overflow-y: auto !important;
    }
}

@media (max-width: 600px) {
    .fixed-height-dialog {
        background-color: whitesmoke;
    }
}


::v-deep .v-list-item__content {
    text-align: left;
}
</style>