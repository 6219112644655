<template>
    <v-container class="mb-12">
        <v-row>
            <v-col class="mt-15">
                <h1>Welcome to mdhub Community</h1>
                <div class="mb-10 text-h6">Discover a wide selection of free templates & questionnaires used by
                    clinicians on mdhub.
                </div>

                <!-- Discover Section -->
                <v-row>
                    <v-col>
                        <div class="d-flex flex-row custom-text-size mt-10 mb-2">Discover what's new</div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col md="6" v-for="item in newItems" :key="item.title">
                        <a :href="item.url" target="_blank" style="text-decoration: none;">
                            <v-card class="mx-auto" max-height="230px">
                                <v-img :src="item.imageUrl" aspect-ratio="16/9">
                                    <v-row class="fill-height ma-0 align-start justify-start">
                                        <v-col cols="12">
                                            <div class="text-overlay mt-8">
                                                <div class="text-h5">{{ item.title }}</div>
                                                <div class="text-subtitle-1">{{ item.subtitle }}</div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-img>
                            </v-card>
                        </a>
                    </v-col>
                </v-row>


                <!-- Category Chips -->

            </v-col>
        </v-row>


        <!-- Community Products Section -->
        <v-row>
            <v-col>
                <div id="title" class="d-flex flex-row mt-10 mb-2 custom-text-size">Templates & Questionnaires by the
                    community,
                    for the community
                </div>
            </v-col>
        </v-row>

        <v-row class="d-flex mb-6 mt-6 pl-5 pr-5">
            <v-text-field class="mt-8" solo-inverted flat hide-details append-icon="mdi-magnify" variant="outlined"
                placeholder="Search everything in Community" v-model="searchQuery"></v-text-field>
        </v-row>
        <v-row class="d-flex mb-6 mt-6 pl-5 pr-5">

            <v-chip-group class="mt-2 me-auto" active-class="red" column v-model="selectedSpecialities" multiple>
                <v-chip v-for="speciality in uniqueSpecialities" filter :key="speciality" label>
                    {{ speciality }}
                </v-chip>
            </v-chip-group>

            <v-select v-model="selectedSortOption" :items="sortOptions" label="Sort By" variant="underlined"
                hide-details class="small-select"></v-select>


        </v-row>

        <v-row v-if="loading">
            <v-col cols="12" class="d-flex justify-center">
                <v-progress-circular class="mt-12" indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else>
            <!-- Card for templates -->
            <v-col v-for="item in sortedItems" :key="item.id" cols="12" sm="6" md="4" lg="4" xl="3" class="pa-2">
                <v-card :id="item.id" v-if="!!item.templateHeader" class="myCard text-left rounded-border"
                    elevation="0">
                    <v-card-text class="d-flex flex-column" style="height:100%">
                        <div class="d-flex justify-space-between">
                            <div>{{ getDisplayName(item.userName) }}</div>
                            <div class="mr-1">
                                <v-icon class="mr-2" size="x-small">mdi-share</v-icon>
                                <v-tooltip text="Times imported" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ item.importsCount }}</span>
                                    </template>
                                </v-tooltip>
                            </div>
                        </div>

                        <p class="text-h6 font-weight-bold mb-1">{{ item.templateHeader }}</p>

                        <div class="d-flex justify-space-between align-center">
                            <div class="d-flex">
                                <v-chip color="var(--mint-macaron)" variant="flat"
                                    class="text-left mt-1 ml-1">Template</v-chip>
                                <v-chip class="text-left mt-1 ml-1">{{ item.selectedSpeciality }}</v-chip>
                            </div>

                        </div>

                        <div class="text-medium-emphasis mt-4">
                            <v-list class="ml-4 mt-0 mb-0 pt-0 pb-0">
                                <div class="description-text" v-for="(detail, i) in item.template"
                                    style="text-align: left;">
                                    <p v-if="i < 3" class="text-caption mb-0 description-text">{{
                                        getSectionTitle(detail) }}</p>
                                    <div v-else-if="i === 3">
                                        <p class="text-caption mb-0 description-text">...</p>
                                        <p class="text-caption mb-0 description-text">
                                            <span class="text-caption text-decoration-underline"> See
                                                all
                                                <v-tooltip activator="parent" location="end">
                                                    <span class="white--text"
                                                        v-html="getDescriptionWithLineBreaks(item.template)"></span>
                                                </v-tooltip>
                                            </span>
                                        </p>

                                    </div>
                                </div>
                            </v-list>
                        </div>

                        <div style="position: absolute; bottom: 15px; right: 15px;">
                            <v-btn elevation="0" @click="selectTemplate(item)">preview</v-btn>
                            <v-btn text="Import" @click="importTemplate(item.id)"
                                :loading="isImporting && item.id === currentId" variant="text">
                                <span class="white--text btn-color"> Import </span>
                            </v-btn>

                        </div>
                    </v-card-text>
                </v-card>

                <v-card :id="item.id" v-else class="myCard text-left rounded-border" elevation="0">
                    <v-card-text class="d-flex flex-column" style="height:100%">
                        <div class="d-flex justify-space-between">
                            <div>{{ getDisplayName(item.userName) }}</div>
                            <div class="mr-1">
                                <v-icon class="mr-2" size="x-small">mdi-share</v-icon>
                                <v-tooltip text="Times imported" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ item.importsCount }}</span>
                                    </template>
                                </v-tooltip>
                            </div>
                        </div>

                        <p class="text-h6 font-weight-bold mb-1">{{ item.title }}</p>


                        <div class="d-flex justify-space-between align-center">
                            <div class="d-flex flex-wrap">
                                <v-chip color="var(--melon)" variant="flat"
                                    class="text-left mt-1 ml-1">Questionaire</v-chip>
                                <v-chip class="mt-1 ml-2">
                                    {{ item.type ===
                                        'questionnaire_intake' ?
                                        'Intake' :
                                        'Follow up' }}
                                </v-chip>
                            </div>

                        </div>
                        <p class="text-caption mt-4 description-text">{{ item.description }}</p>

                        <div style="position: absolute; bottom: 15px; right: 15px;">
                            <v-btn elevation="0"
                                @click="selectedQuestionnaire = item; previewDrawer = true">preview</v-btn>
                            <v-btn text="Import" variant="text" :loading="isImporting && item.id === currentId"
                                @click="importQuestionnaire(item.id)">
                                <span class="white--text btn-color"> Import
                                </span>
                            </v-btn>

                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-navigation-drawer v-model="previewDrawer" width="90%" temporary location="right" class="nav-drawer"
            :scrim="true">
            <v-card class="py-10 px-4 overflow-y-auto" style="min-height: 100%;">
                <div style="padding:0 0 16px 0;">
                    <h5 class="text-left">Questionnaire Preview</h5>
                    <p class="text-left">See how patient responses are presented and summarized in a personalized
                        report.</p>
                </div>
                <div class="layout-container">
                    <!-- Questionnaire Sections -->
                    <div class="section mr-4">
                        <h6 class="text-left">Sections</h6>
                        <ol class="list-unstyled">
                            <li @click="scrollToItem(item.id)" class="mb-2 text-left sectionHeader"
                                v-for="(item, index) in displayedItems" :key="item.id">
                                <span class="text-caption">{{ `${index + 1}. ${item.title}` }}</span>
                            </li>
                        </ol>
                    </div>

                    <div class="rounded-border mr-2 pa-4 item-preview-container">
                        <h3 class="text-left">{{ selectedQuestionnaire?.title }}</h3>
                        <p class="text-left">{{ selectedQuestionnaire?.description }}</p>
                        <ItemPreview :id="item.id" :overlay="false" v-for="(item, i) in selectedQuestionnaire?.schema"
                            :key="i" :item="item" />
                    </div>
                    <div v-if="selectedQuestionnaire?.previewSummary" class="preview-summary">
                        <v-card-text class=" rounded-border align-left">
                            <h3 style="text-align: left; padding-left:14px">Example report</h3>
                            <QuillEditor v-model="selectedQuestionnaire.previewSummary" class="preview" />
                        </v-card-text>
                    </div>
                </div>
            </v-card>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="previewTemplateDrawer" width="600" temporary location="right">
            <div class="d-flex flex-column pa-5 card-rounded" style="width:100%" v-if="!!selectedTemplate">
                <h3 class="align-self-start">{{ selectedTemplate?.templateHeader }}</h3>
                <v-select label="Note language" v-model="selectedTemplate.language" readonly
                    :items="['English', 'Spanish', 'French', 'German', 'Arabic', 'Chinese', 'Punjabi', 'Tagalog']"></v-select>
                <v-select label="Specialty" v-model="selectedTemplate.selectedSpeciality" readonly
                    :items="['Psychiatry', 'Psychology', 'Child and Adolescent Psychiatry',
                        'Geriatric Psychiatry', 'Forensic Psychiatry', 'Addiction Psychiatry', 'Consultation-Liaison Psychiatry', 'Neuropsychiatry', 'Psychosomatic Medicine', 'Emergency Psychiatry', 'Sleep Medicine', 'Clinical Psychology', 'Counseling Psychology', 'School Psychology', 'Industrial-Organizational Psychology', 'Health Psychology', 'Neuropsychology', 'Forensic Psychology', 'Sports Psychology', 'Developmental Psychology', 'Social Psychology', 'Experimental Psychology',
                        'Community Psychology', 'Anesthesiology', 'Cardiology', 'Dermatology', 'Emergency Medicine', 'Endocrinology', 'Family Medicine', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Hematology', 'Infectious Disease', 'Internal Medicine', 'Nephrology', 'Neurology', 'Nutritionist', 'Obstetrics and Gynecology', 'Oncology', 'Ophthalmology', 'Orthopedics', 'Otolaryngology', 'Pediatrics', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Pulmonology', 'Radiology', 'Rheumatology', 'Urology', 'Other',]"></v-select>
                <h5 v-if="inputValues.length > 0" class="align-self-start">Sections</h5>
                <TemplateSectionPreview class="rounded-card  pa-3 mb-3" :preview="true"
                    v-for="(item, index) in inputValues" :element="item" :index="index">
                </TemplateSectionPreview>
            </div>
        </v-navigation-drawer>

        <Feedback />
        <v-snackbar v-model="snackbar" color="gray" elevation="24">
            <div>{{ snackbarMessage }}</div>

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbarButtonClick">
                    <span class="white--text btn-color"> See all </span>

                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import getCollectionGlobal from '@/composables/getCollectionGlobal'
import { getFunctions, httpsCallable } from "firebase/functions";
import ItemPreview from '@/components/questionnaire-builder/ItemPreview.vue';
import { Timestamp } from "@firebase/firestore";
import { trackEvent, QUESTIONNAIRE_IMPORTED, QUESTIONNAIRE_IMPORTED_FAILED } from '@/utilities/analyticsService';
import { useRoute, useRouter } from 'vue-router';
import Feedback from '@/components/Feedback.vue';
import QuillEditor from '@/views/patients/QuillEditor.vue';
import TemplateSectionPreview from '../components/TemplateSection.vue';

const _ = require("lodash");

const route = useRoute();
const router = useRouter();

const { user } = getUser();

const functions = getFunctions();

const templates = ref([])
const questionnaires = ref([])

const selectedSpecialities = ref([])

const searchQuery = ref('')
let switchedAlready = false
watch(searchQuery, () => {
    if ((route.query.questionnairesSelected === 'true' || route.query.templatesSelected === 'true') && !switchedAlready) {
        selectedSpecialities.value = []
        switchedAlready = true
    }
})

const selectedSortOption = ref('Popularity')
const sortOptions = ['Alphabetically', 'Popularity', 'Oldest', 'Newest']
const newItems = [
    {
        title: 'Watch our tutorials', subtitle: '3 videos', imageUrl: require('@/assets/tutorials.png'), url: 'https://www.youtube.com/@mdhubInc/videos'
    },
    {
        title: 'Read our blog', subtitle: '10 posts', imageUrl: require('@/assets/mdhub-blog.png'), url: 'https://www.mdhub.ai/blog'
    },
]

const loading = ref(false)
onMounted(async () => {
    loading.value = true
    const getSharedQuestionnaires = httpsCallable(functions, 'getSharedQuestionnaires');
    const getCommunitySharedTemplates = httpsCallable(functions, 'getCommunitySharedTemplates');

    const [questionnairesResult, templatesResult] = await Promise.all([
        getSharedQuestionnaires(),
        getCommunitySharedTemplates()
    ])

    questionnaires.value = questionnairesResult.data.questionnaires
    templates.value = templatesResult.data.templates


    if (route.query.questionnairesSelected === 'true') {
        selectedSpecialities.value.push(0)
        nextTick(() => {
            const element = document.getElementById('title')
            element.scrollIntoView({
                behavior: 'smooth',
                alignToTop: true
            })
        })
    }
    if (route.query.templatesSelected === 'true') {
        selectedSpecialities.value.push(1)
        nextTick(() => {
            const element = document.getElementById('title')
            element.scrollIntoView({
                behavior: 'smooth',
                alignToTop: true
            })
        })
    }
    loading.value = false
})



const filteredTemplates = computed(() => {
    let filtered = templates.value || [];

    if (selectedSpecialities.value.length > 0) {
        const actualSpecialities = selectedSpecialities.value.map(index => uniqueSpecialities.value[index]);

        filtered = filtered.filter(template =>
            actualSpecialities.includes(template.selectedSpeciality)
        );
    }

    if (selectedSpecialities.value.length > 0 && selectedSpecialities.value.map(i => uniqueSpecialities.value[i]).includes('All Templates')) {
        filtered = templates.value;
    }

    if (searchQuery.value) {
        const searchLower = searchQuery.value.toLowerCase();
        filtered = filtered.filter(template =>
            template.template.some(detail => detail.toLowerCase().includes(searchLower)) ||
            template.userName.toLowerCase().includes(searchLower) ||
            template.templateHeader.toLowerCase().includes(searchLower)
        );
    }

    return filtered;
})

const filteredQuestionnaires = computed(() => {
    let filtered = questionnaires.value || [];

    if (selectedSpecialities.value.length > 0 && !selectedSpecialities.value.map(i => uniqueSpecialities.value[i]).includes('Questionnaires')) {
        filtered = [];
    }

    if (searchQuery.value) {
        const searchLower = searchQuery.value.toLowerCase();
        filtered = filtered.filter(questionnaire =>
            questionnaire.title.toLowerCase().includes(searchLower) ||
            questionnaire.description.toLowerCase().includes(searchLower) ||
            questionnaire.type.toLowerCase().includes(searchLower)
        )
    }

    return filtered;
})

const sortedItems = computed(() => {
    let sorted = [...filteredTemplates.value, ...filteredQuestionnaires.value];

    switch (selectedSortOption.value) {
        case 'Alphabetically':
            sorted.sort((a, b) => {
                const aHeader = a.templateHeader || a.title;
                const bHeader = b.templateHeader || b.title;
                return aHeader.localeCompare(bHeader);
            });
            break;
        case 'Popularity':
            sorted.sort((a, b) => b.importsCount - a.importsCount);
            break;
        case 'Oldest':
            sorted.sort((a, b) => {
                const aDate = new Timestamp(a.createdAt._seconds, a.createdAt._nanoseconds)
                const bDate = new Timestamp(b.createdAt._seconds, b.createdAt._nanoseconds)
                return aDate.toDate() - bDate.toDate()
            });
            break;
        case 'Newest':
            sorted.sort((a, b) => {
                const aDate = new Timestamp(a.createdAt._seconds, a.createdAt._nanoseconds)
                const bDate = new Timestamp(b.createdAt._seconds, b.createdAt._nanoseconds)
                return bDate.toDate() - aDate.toDate()
            });
            break;
    }
    return sorted;
})

const uniqueSpecialities = computed(() => {
    const specialities = new Set();
    if (questionnaires.value.length > 0) {
        specialities.add('Questionnaires')
        // specialities.add('Templates')
    }
    if (templates.value.length > 0 && Array.isArray(templates.value)) {
        specialities.add('All Templates')
        templates.value.forEach(template => {
            if (template.selectedSpeciality) {
                specialities.add(template.selectedSpeciality);
            }
        });
    }

    return Array.from(specialities);
})

const snackbar = ref(false)
const snackbarMessage = ref('')

const isImporting = ref(false)
const currentId = ref(null)

const importTemplate = async (templateId) => {
    console.log('Import template')
    currentId.value = templateId;
    isImporting.value = true
    const importTemplate = httpsCallable(functions, 'importTemplate');
    try {
        const result = await importTemplate({ userId: user.value.uid, templateId: templateId });
        snackbarMessage.value = 'Template imported successfully'
        snackbar.value = true

    } catch (error) {
        console.log(`Error importing template ${error}`)

    }

    currentId.value = null
    isImporting.value = false
}

const importQuestionnaire = async (questionnaireId) => {
    // if (!canImportQuestionnaire.value) {
    //     snackbar.value = true
    //     return
    // }
    currentId.value = questionnaireId;
    isImporting.value = true

    const submitImportQuestionnaire = httpsCallable(functions, 'importQuestionnaire');

    try {
        await submitImportQuestionnaire({ questionnaireId: questionnaireId });
        snackbarMessage.value = 'Questionnaire imported successfully'
        snackbar.value = true
        trackEvent(QUESTIONNAIRE_IMPORTED, { userId: user.value.uid, userEmail: user.value.email, questionnaireId })

    } catch (error) {
        console.log(`Error importing questionnaire ${error}`)
        trackEvent(QUESTIONNAIRE_IMPORTED_FAILED, { userId: user.value.uid, userEmail: user.value.email, questionnaireId, error })

    }
    currentId.value = null
    isImporting.value = false
}

const snackbarButtonClick = () => {
    if (snackbarMessage.value === 'Template imported successfully') {
        router.push('/templates')
    } else {
        router.push('/questionnaires')
    }
}


const getSectionTitle = (templateItem) => {
    const title = templateItem.split('/');
    return title[0];
}

const selectedQuestionnaire = ref(null)
const previewDrawer = ref(false)

const selectedTemplate = ref(null)
const previewTemplateDrawer = ref(false)
const inputValues = ref([])

const selectTemplate = (template) => {
    selectedTemplate.value = template
    previewTemplateDrawer.value = true
    const transformedArray = template.template.map(item => {
        const [title, dlevel, style] = item.split('/');
        return {
            item: title,
            dlevel: dlevel,
            style: style,
        };
    })
    inputValues.value = transformedArray;
}

const scrollToItem = (id) => {
    nextTick(() => {
        const element = document.getElementById(id)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
    })

}

const getDescriptionWithLineBreaks = (description) => {
    if (Array.isArray(description)) {
        var items = ""
        description.forEach(item => {
            const itemFull = item.split("/")
            const description = itemFull[0]
            items = items + description + "/"
        });
        const words = items.split('/');
        const descriptionWithLineBreaks = words.join('<br>');
        return descriptionWithLineBreaks
    }

    const words = description.trim().split('/');
    const descriptionWithLineBreaks = words.join('<br>');
    return descriptionWithLineBreaks;
}

const displayedItems = computed(() => {
    return selectedQuestionnaire?.value?.schema.filter(item => item.type === 'input_title')
})

const getDisplayName = (userName) => {
    // map internal names to mdhub
    const internalNames = [
        'Bogdan',
        'Keerthana',
        'Dom',
        'Jost',
        'Giorgos',
        'Vasileios',
        'Efren'
    ];

    const containsName = internalNames.find(name => userName?.includes(name));
    if (containsName) {
        return 'mdhub';
    }

    return userName;


}

watch(previewDrawer, (isOpen) => {
    if (isOpen) {
        document.body.classList.add('overflow-hidden')
    } else {
        document.body.classList.remove('overflow-hidden')
        nextTick(() => {
            const element = document.getElementById(selectedQuestionnaire.value.id)
            element.scrollIntoView({
                behavior: 'smooth',
                alignToTop: true
            })
        })
    }
})

watch(previewTemplateDrawer, (isOpen) => {
    if (isOpen) {
        document.body.classList.add('overflow-hidden')
    } else {
        document.body.classList.remove('overflow-hidden')
        nextTick(() => {
            const element = document.getElementById(selectedTemplate.value.id)
            element.scrollIntoView({
                behavior: 'smooth',
                alignToTop: true
            })
        })
    }
})
</script>

<style scoped>
.white--text {
    color: #FFF !important;
}

.text-overlay {
    position: relative;
    top: 0;
    left: 26px;
    text-align: left;
    color: #FFF;
}

.text-h5 {
    color: #FFF;
}

.text-subtitle-1 {
    color: #FFF;
}

.custom-text-size {
    font-size: 22px;
    font-weight: 600;
}

.small-select {
    max-width: 170px;
    font-size: 14px;
    height: 30px;
}

.small-select .v-input__control {
    min-height: 30px;
}

.small-select .v-select__slot {
    min-height: 30px;
}

.small-select .v-select__selections {
    min-height: 30px;
    line-height: 30px;
}


.fixed-size-card {
    max-height: 216px;
    width: 100%;
    height: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 2%;
}

.card-hover-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    visibility: hidden;
    color: #fff !important;

}

.btn-color {
    color: #FF7260 !important;
}

::v-deep .card-hover-button .v-btn__content {
    color: #fff !important;
}

.text-left {
    text-align: left;
}

.card-container:hover .card-hover-button {
    visibility: visible;
}

.staytop {
    position: fixed;
    top: 50px;
    /* Adjust as needed */
    width: 20%;
    /* Equivalent to cols="2" */
    z-index: 100;
    padding: 10px;
}

.sectionHeader {
    cursor: pointer;

}

.sectionHeader:hover {
    color: var(--bittersweet);
    text-decoration: underline;
}

.myCard {
    /* height: 300px; */
    height: 40vh;
}

@media (max-width: 768px) {
    .myCard {
        height: 42.5vh;
    }
}

@media (max-width: 480px) {
    .myCard {
        height: 45vh;
    }
}

.description-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    /* Change this number to set max lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-hidden {
    overflow-y: hidden !important;
}

.responses {
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    border-radius: 4px;
    overflow-y: scroll;
}

.item-preview-container {
    width: 38%;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */

    padding: 16px;
    border: 1.5px solid rgba(224, 224, 224, 0.5);
    height: fit-content;
}

.nav-drawer {
    max-width: 90vw;
}

.layout-container {
    display: flex;
    justify-content: space-between;
}

.section {
    width: 10%;
    flex: 0 0 auto;
}

.item-preview-container {
    flex: 1 1 auto;
    padding: 0 16px;
}

.preview-summary {
    width: 38%;
    flex: 1 1 auto;
}

/* Responsive styles */
@media (max-width: 1049px) {
    .layout-container {
        flex-direction: column;
    }

    .section,
    .item-preview-container,
    .preview-summary {
        width: 100% !important;
        margin-bottom: 1rem;
    }

    .item-preview-container {
        padding: 0;
    }
}

.rounded-card {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}
</style>