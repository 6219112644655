<template>
    <div>
        <v-navigation-drawer v-model="drawer" temporary location="right" :width="900" :persistent="true">
            <div class="recording-drawer">

                <Head />

                <Body />
            </div>

        </v-navigation-drawer>

        <v-overlay :model-value="overlay" :persistent="true" class="align-center justify-center spinner-overlay">
            <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>

</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue';
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import Head from './drawer/Head.vue';
import Body from './drawer/Body.vue';
import { trackEvent, RECORDER_TRIED_REFRESH } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'

const { user } = getUser();

const store = useRecorderStore();
const { drawer, overlay, recording, sessionId, patientId } = storeToRefs(store)

onMounted(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
})

onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
})

const handleBeforeUnload = (e) => {
    if (recording.value || overlay.value) {
        trackEvent(RECORDER_TRIED_REFRESH, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value });
        e.preventDefault();
    }
}
</script>

<style scoped>
.recording-drawer {
    height: calc(100vh - 64px);
}
</style>
